<template>
  <div class="admin-container">
    <CloudBackground/>
    <img src="@/assets/illustrations/aircraft-side.svg" alt="More Flights">
    <v-card class="admin-card">
      <div class="admin-title">
        Choose Your Pilot
      </div>
      <div class="admin-desc">
        You can see all the detail about flight hours, schedule, logbook and many more about the pilot you selected.
      </div>
      <v-autocomplete
        v-if="$store.state.logbook.pilots.length"
        v-model.number="adminPilotSelected"
        :items="$store.state.logbook.pilots"
        item-text="fullname"
        item-value="id"
        prepend-inner-icon="mdi-magnify"
        hide-details
        placeholder="Select Pilot"
        class="search-pilot rounded-pill pt-0 mt-6"
      />
      <div v-else class="text-center mt-6">
        <LittleLoading :show="true" class="mt-7" />
        <div class="font-14 mt-2">
          Please wait a moment
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>

export default {
  data: () => ({
    adminPilotSelected: null
  }),
  watch: {
    adminPilotSelected: function (v) {
      if (v) {
        this.$store.dispatch('DO_LOGOUT', true)
        setTimeout(() => {
          this.$store.dispatch('DO_LOGIN_PILOT_BY_ADMIN', v)
        }, 150)
      } else {

      }
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/apps';
@import '@/assets/scss/fonts';

@keyframes upDown {
  0% {
    transform: translate(-50%, -10px) rotate(0deg)
  }
  50% {
    transform: translate(-50%, 20px) rotate(-2deg)
  }
  100% {
    transform: translate(-50%, -10px) rotate(0deg)
  }
}
.admin-container{
  background-color: $primary;
  position: fixed;
  left: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  height: 100%;
  z-index: 205;
  img{
    position: absolute;
    top: 10vh;
    left: 50%;
    width: calc(200px + .4vw);
    height: calc(200px + .4vw);
    animation: upDown 9.5s ease-in-out infinite;
  }
  .admin-card{
    background-color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    border-radius: 50px 50px 0 0;
    min-height: 340px;
    padding: 50px 30px;
    .admin-title{
      @include font-size(28px);
      font-weight: 700;
      margin-bottom: 44px;
    }
    .admin-desc{
      @include font-size(16px);
    }
    .search-pilot{
      .v-input__slot{
        padding: 0 25px !important;
        &:before,
        &:after{
          border: 0 !important;
          width: 0;
        }
      }
      .v-input__prepend-inner{
        align-self: center !important;
        margin-top: 0 !important;
        margin-right: 8px;
      }
    }
  }
}
</style>
